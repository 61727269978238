import React, { useState, useEffect } from 'react';
import Hero from './Hero/Hero';
import About from './About/About';
import Projects from './Projects/Projects';
import Qualifications from './Qualifications/Qualifications';
import Videos from './Videos/Videos';
import Courses from './Courses/Courses';
import Skills from './Skills/Skills';
import Contact from './Contact/Contact';
import Footer from './Footer/Footer';
import Navbar from './Navbar/Navbar';

import { PortfolioProvider } from '../context/context';

import {
  heroData,
  aboutData,
  projectsData,
  qualificationsData,
  videosData,
  coursesData,
  contactData,
  socialBitcoinWebData,
} from '../mock/data';

function App() {
  const [hero, setHero] = useState({});
  const [about, setAbout] = useState({});
  const [projects, setProjects] = useState([]);
  const [qualifications, setQualifications] = useState([]);
  const [videos, setVideos] = useState([]);
  const [courses, setCourses] = useState([]);
  const [contact, setContact] = useState({});
  const [footer, setFooter] = useState({});

  useEffect(() => {
    setHero({ ...heroData });
    setAbout({ ...aboutData });
    setProjects([...projectsData]);
    setQualifications([...qualificationsData]);
    setVideos([...videosData]);
    setCourses([...coursesData]);
    // Blog posts
    setContact({ ...contactData });
    setFooter({ ...socialBitcoinWebData });
  }, []);

  return (
    <PortfolioProvider
      value={{ hero, about, projects, qualifications, videos, courses, contact, footer }}
    >
      <Navbar />
      <Hero />
      <About />
      <Projects />
      <Qualifications />
      <Videos />
      <Courses />
      <Skills />
      <Contact />
      <Footer />
    </PortfolioProvider>
  );
}

export default App;
