import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import Tilt from 'react-tilt';
import PortfolioContext from '../../context/context';
import Title from '../Title/Title';
import QualificationImg from '../Image/QualificationImg';

const Qualifications = () => {
  const { qualifications } = useContext(PortfolioContext);
  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="qualifications">
      <Container>
        <Title title="Academic Qualifications" />
        {qualifications.map((qualification) => {
          const { name, description, img, id } = qualification;

          return (
            <Row key={id}>
              <Col lg={4} sm={12}>
                <Fade
                  right={isDesktop}
                  bottom={isMobile}
                  duration={1000}
                  delay={1000}
                  distance="30px"
                >
                  <div className="qualification-wrapper__image">
                    <Tilt
                      options={{
                        reverse: false,
                        max: 8,
                        perspective: 1000,
                        scale: 1,
                        speed: 300,
                        transition: true,
                        axis: null,
                        reset: true,
                        easing: 'cubic-bezier(.03,.98,.52,.99)',
                      }}
                    >
                      <div data-tilt className="thumbnail rounded">
                        <QualificationImg alt={name} filename={img} />
                      </div>
                    </Tilt>
                  </div>
                </Fade>
              </Col>
              <Col lg={4} sm={12}>
                <Fade
                  left={isDesktop}
                  bottom={isMobile}
                  duration={1000}
                  delay={500}
                  distance="30px"
                >
                  <div className="qualification-wrapper__text">
                    <h3 className="qualification-wrapper__text-title">
                      {name || 'Qualification Title'}
                    </h3>
                    <p>{description || ''}</p>
                  </div>
                </Fade>
              </Col>
            </Row>
          );
        })}
      </Container>
    </section>
  );
};

export default Qualifications;
