import React, { useEffect, useState } from 'react';

import { Container } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import Tilt from 'react-tilt';
import Title from '../Title/Title';
import skillcloud from '../../images/skillcloud.png';

const Skills = () => {
  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="skills">
      <Container>
        <Fade right={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
          <div className="course-wrapper__image">
            <Tilt
              options={{
                reverse: false,
                max: 8,
                perspective: 1000,
                scale: 1,
                speed: 300,
                transition: true,
                axis: null,
                reset: true,
                easing: 'cubic-bezier(.03,.98,.52,.99)',
              }}
            >
              <div className="skills-wrapper">
                <Title title="Skills & Experience" />
                <img alt="B" className="skillcloud" src={skillcloud} />
              </div>
            </Tilt>
          </div>
        </Fade>
      </Container>
    </section>
  );
};

export default Skills;
