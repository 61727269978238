import React from 'react';
import { Link } from 'gatsby';
import { Container } from 'react-bootstrap';

const navbar = () => {
  return (
    <section id="navbar">
      <Container>
        <div>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/blog">Blog</Link>
            </li>
          </ul>
        </div>
      </Container>
    </section>
  );
};
export default navbar;
